import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { useLocalization } from "gatsby-theme-i18n";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import esgDocs from "../../../cms/esgDocs/esgDocs";
import financialInformationDocs from "../../../cms/financialInformationDocs/financialInformationDocs";
import Container from "../../../components/Container";
import DocumentList from "../../../components/DocumentList";
import InterestedItems from "../../../components/InterestedItems";
import NewLayout from "../../../components/NewLayout";
import Tabs from "../../../components/Tabs";
import ToggleButtonGroupCustomized from "../../../components/ToggleButtonGroupCustomized";
import { headers } from "../../../utils/navbar";

const DOCUMENT_TYPES = [{ value: "financial", label: "Financial Reports" }, { value: "annual", label: "Annual Reports" }, { value: "esg", label: "ESG Reports" }, { value: "presentations", label: "Presentations" }];
const FINANCIAL_REPORTS_TYPES = [{ value: "Press_Release" }, { value: "Financial_Reports_Presentations", label: "Presentation" }, { value: "Excel" }, { value: "Financial_Reports", label: "Interim Report" }, { value: "Recording" }];
const FINANCIAL_REPORTS_HEADERS = [
  { label: "Type", width: 250 },
  ...FINANCIAL_REPORTS_TYPES.map(({ value, label }) => ({ label: label || value.replaceAll("_", " "), value, width: 250 })),
];
const FINANCIAL_KEY = "financial";

function InvestorsFinancialReportsPage() {
  const { locale } = useLocalization();
  const [currentTab, setCurrentTab] = React.useState(DOCUMENT_TYPES[0].value);
  const data = financialInformationDocs[locale];
  const interestedItems = [
    { ..._.get(headers(), "investors.elements.investors_about") },
    { ..._.get(headers(), "investors.elements.share_information") },
    { ..._.get(headers(), "investors.elements.financial_information_reports.elements.financial_calendar") },
  ];

  const financialReportsTypes = FINANCIAL_REPORTS_TYPES.map(({ value }) => value);

  let content = {
    financial: data.filter(({ attributes: { category } }) => financialReportsTypes.includes(category)),
    annual: data.filter(({ attributes: { category } }) => category === "Annual_Reports"),
    esg: esgDocs[locale].filter(({ attributes: { category } }) => category === "ESG_Annual_Report"),
    presentations: data.filter(({ attributes: { category } }) => category === "Presentations"),
  };

  const currentYears = _.uniq(content[currentTab].map(({ attributes }) => attributes.publish_year)).sort().reverse();
  const [selectedYear, setSelectedYear] = useState(currentYears[0]);

  content = Object.keys(content).reduce((acc, key) => {
    acc[key] = _.groupBy(content[key], "attributes.publish_year");
    return acc;
  }, {});

  content = currentTab === FINANCIAL_KEY ? _.groupBy(content[currentTab][selectedYear], "attributes.document_subcategory.data.attributes.title") : content[currentTab][selectedYear];

  useEffect(() => setSelectedYear(currentYears[0]), [currentTab]);

  return (
    <NewLayout
      byDefault
      apps="investors.financial_information_reports.financial_reports"
    >
      <Container pb={8}>
        <Typography mt={3} variant="h2">
          Allfunds Financial Information And Reports
        </Typography>
        <Box mt={2}>
          <Tabs
            tabs={currentYears}
            value={selectedYear}
            onChange={(_, value) => setSelectedYear(value)}
          />
          <Box mt={5} mb={{ xs: 1, sm: 3 }}>
            <ToggleButtonGroupCustomized
              tab={currentTab}
              items={DOCUMENT_TYPES}
              onChangeTab={(value) => setCurrentTab(value)}
            />
          </Box>
          {currentTab === FINANCIAL_KEY ?
            <Table>
              <TableHead>
                <TableRow>
                  {FINANCIAL_REPORTS_HEADERS.map(({ label, width }, index) => <TableCell width={width} align={index === 0 ? "start" : "center"}><Typography fontSize={16} fontWeight={600}>{label}</Typography></TableCell>)}
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(content)?.map(([subcategory, values]) => {
                  if (subcategory === "undefined") return null;
                  return (
                    <TableRow>
                      <TableCell><Typography fontWeight={600}>{subcategory}</Typography></TableCell>
                      {financialReportsTypes.map((cat) => {
                        const groupByCategory = values.filter(({ attributes: { category } }) => category === cat);
                        return <TableCell align="center">
                          <DocumentList items={groupByCategory} direction="column" noResults="-" />
                        </TableCell>;
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            : <DocumentList items={content} direction="row" noResults="-" />}
        </Box>
        <InterestedItems items={interestedItems} />
      </Container>
    </NewLayout>
  );
}

export default InvestorsFinancialReportsPage;
